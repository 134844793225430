<template>
  <section
    :class="[
      'side-filter__item-content-ul',
      depth ? 'side-filter__item-content-ul_indent' : '',
    ]"
    :role="hasChild ? 'treeitem' : 'option'"
  >
    <s-radio
      v-model="curId"
      toggle
      tabindex="0"
      :label="selfData.label_id"
      size="radio12"
      :data-cat-id="selfData.label_id"
      :da-event-click="analysis.cateClickEventId"
      gap="0"
      :disabled="disabled"
      @change="radioChange"
    >
      {{ selfData.label }}
    </s-radio>
    <ClientOnly>
      <template v-if="hasChild && loaded">
        <span v-show="open">
          <sui_icon_min_16px
            class="tree-menu__icon"
            size="16px"
            @click.stop="toggle(selfData.children)"
          />
        </span>

        <span v-show="!open">
          <sui_icon_add_16px
            class="tree-menu__icon"
            size="16px"
            @click.stop="toggle(selfData.children)"
          />
        </span>
      </template>
    </ClientOnly>
    <template v-if="hasChild">
      <TreeMenu
        v-for="(item, childIndex) in selfData.children"
        v-show="open"
        :key="item.label_id"
        :index="childIndex"
        :analysis="analysis"
        :data="item"
        :depth="depth + 1"
        :panel-config="panelConfig"
        :loaded="loaded"
        :cur-selected-id="curSelectedId"
        :disabled="disabled"
        @get-cur-selected-id="_getCurSelectedId"
        @filter-change="_filterChange"
        @toggle-child-panel="_toggleChildPanel"
      />
    </template>
  </section>
</template>

<script>
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SRadio } from '@shein-aidc/sui-radio'
import { sui_icon_min_16px, sui_icon_add_16px  } from '@shein-aidc/icon-vue3'
import { daEventCenter } from 'public/src/services/eventCenter'

export default {
  name: 'TreeMenu',
  components: {
    ClientOnly,
    SRadio,
    sui_icon_min_16px,
    sui_icon_add_16px,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: -1,
    },
    analysis: {
      type: Object,
      default: () => ({}),
    },
    depth: {
      type: Number,
      default: 0,
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    curSelectedId: {
      type: [String, Number],
      default: '',
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selfData: {}, // 筛选数据
      open: false, // 展开树结构
      curId: '',
    }
  },
  computed: {
    hasChild() {
      return (
        this.selfData.children &&
        Array.isArray(this.selfData.children) &&
        this.selfData.children.length
      )
    },
  },
  watch: {
    data: {
      handler(data) {
        this.selfData = data
        this.open = this.selfData.open
      },

      immediate: true,
    },
    curSelectedId: {
      handler(id) {
        this.curId = id
      },

      immediate: true,
    },
    open(bol) {
      bol &&
        this.$nextTick(() => {
          const { analysis } = this
          if (analysis && analysis.cateExposeEventId) {
            daEventCenter.triggerNotice({
              daId: analysis.cateExposeEventId,
              extraData: {
                data: this.selfData.children,
              },
            })
          }
        })
    },
  },
  methods: {
    // 展开收起筛选项
    toggle (data, val) {
      this.open = val || !this.open
      this._toggleChildPanel({
        data,
        openStatus: this.open,
        key: this.panelConfig.key,
      })
    },
    // 点击筛选项
    radioChange (radioVal) {
      if (this.disabled) return
      this.hasChild && this.loaded && this.toggle(this.selfData.children, Boolean(radioVal))
      this.panelConfig.data['lastSelectedIndex'] = this.index
      this.panelConfig['curSelectedId'] = radioVal // 实时更新当前选中id，当全局重置触发时，触发Tree中的watch，从而重置tree选中项

      this._getCurSelectedId(radioVal)

      // 选中或者反选
      let operationType = ''
      if (radioVal) {
        operationType = 'select'
      } else {
        operationType = 'delete'
      }

      this._filterChange({
        ...this.selfData,
        ...{ panelConfig: this.panelConfig, operationType },
      })
    },
    _filterChange(payload) {
      this.$emit('filterChange', payload)
    },
    _getCurSelectedId(id) {
      this.$emit('getCurSelectedId', id)
    },
    _toggleChildPanel(data) {
      this.$emit('toggleChildPanel', data)
    },
  },
  emits: ['filterChange', 'getCurSelectedId', 'toggleChildPanel'],
}
</script>

<style lang="less">
.side-filter {
  &__item-content-ul {
    position: relative;
    margin-top: 8px;
    line-height: 18px;
    .tree-menu__icon {
      position: absolute;
      .right(0);
      top: 0;
      font-weight: 400;
      color: #aeaeae;
      font-size: 16px;
      cursor: pointer;
    }
    &_indent {
      .padding-l(20px);
    }
    .sui-radio {
      width: 100%;
      &__label {
        display: inline-block;
        width: calc(100% - 28px);
        overflow: visible;
        text-overflow: unset;
        white-space: normal;
      }
      &.sui-radio__disabled {
        cursor: pointer;
      }
    }
    .sui-radio__disabled .sui-radio__label {
      color: #222;
    }
    .sui-radio__disabled .sui-radio__check {
      border-color: #ccc;
      background-color: #fff;
    }
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-radio__checked.sui-radio__disabled .sui-radio__check {
      border-color: #959595;
      background-color: #fff;
    }
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-radio__checked.sui-radio__disabled .sui-radio__check-select {
      background-color: @sui_color_brand;
    }
  }
}
</style>
