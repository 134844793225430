<template>
  <div
    :class="{
      'side-filter__item-content-two': isDirectionColumnImg
    }"
  >
    <div
      v-for="item in data"
      :key="item.label_id"
      :class="{
        'side-filter__item-content-each side-filter__item-content-each_img': !isDirectionColumnImg,
        'side-filter__item-content-two_img': isDirectionColumnImg,
      }"
      :style="panelConfig.itemStyles"
    >
      <template v-if="item.img">
        <s-checkbox
          v-model="item.isSelected"
          tabindex="0"
          :custom="true"
          :label="item.label_id"
          gap="0"
          :disabled="disabled"
          @change="clickFilterItem(item)"
        >
          <img
            :src="lazyImg"
            :data-src="item.img"
            :alt="item.label"
            :title="item.label"
            class="lazyload"
          />
          <span
            v-if="isDirectionColumnImg"
            class="side-filter__item-content-two_imgText"
          >{{ item.label }}</span>
          <s-label
            v-if="item.isHot"
            class="side-filter__text"
            type="promo"
            aria-hidden="true"
          >
            <span class="side-filter__text-hot">Hot</span>
          </s-label>
        </s-checkbox>
      </template>
    </div>
  </div>
</template>

<script>
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import { SLabel } from '@shein-aidc/sui-label'

export default {
  name: 'SymbolItem',
  components: {
    SCheckbox,
    SLabel,
  },
  props: {
    language: {
      type: Object,
      default: () => ({}),
    },
    analysis: {
      type: Object,
      default: () => ({}),
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    lazyImg: {
      type: String,
      default: '',
    },
    transformImg: {
      type: [String, Function],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      open: false, // viewMore展开
      data: {},
    }
  },
  inject: {
    isImgDataTypeDirectionColumnAbtOn: {
      default: () => () => false
    }
  },
  computed: {
    isDirectionColumnImg() {
      return this.isImgDataTypeDirectionColumnAbtOn()
    }
  },
  watch: {
    panelConfig: {
      handler(panelConfig) {
        this.data = panelConfig?.data?.children || []
      },

      immediate: true,
    },
  },
  methods: {
    // 点击view more
    clickViewMore() {
      this.open = true
    },
    // 点击筛选项
    clickFilterItem(item) {
      if (this.disabled) return

      // // 选中或者反选
      let operationType = ''
      if (item.isSelected) {
        operationType = 'select'
      } else {
        operationType = 'delete'
      }
      this.$emit('filterChange', {
        ...item,
        ...{ panelConfig: this.panelConfig, operationType },
      })
    },
  },
  emits: ['filterChange'],
}
</script>

<style lang="less">
.side-filter__item-content-two {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  &_img {
    width: 50%;
    margin-top: 12px;

    .sui-checkbox {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
    }

    img {
      width: 24px;
      height: 24px;
      border: 1px solid #e6e6e6 /*rtl:ignore*/;
      cursor: pointer;
      border-radius: 100%;
      &:hover {
        border-color: #222;
        padding: 2px;
      }
    }

    .sui-checkbox__checked {
      >img {
        border-color: #222;
        padding: 2px;
      }
    }
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-checkbox__checked.sui-checkbox__disabled .sui-checkbox__label-select {
      background-color: #222;
      border-color: #222;
      fill: #fff;
    }
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-checkbox__disabled .sui-checkbox__label-select {
      background-color: #fff;
      border-color: #ccc;
      fill: #fff;
    }
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-checkbox__disabled .sui-checkbox__label-text {
      color: #222;
    }
  }

  &_imgText {
    .line-camp();
    flex: 1;
    margin-left: 6px;
    color: #222;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    white-space: normal;
  }
}
.side-filter__item-content-each_img {
  .fl();
  position: relative;
  margin-top: 8px;
  line-height: 18px;
  min-width: auto;
  &:not(:nth-of-type(5n)) {
    .margin-r(17px);
  }
  .sui-checkbox {
    position: relative;
  }
  .side-filter__text {
    position: absolute;
    width: 26px;
    height: 15px;
    font-size: 12px;
    border-radius: 2px;
    z-index: @zindex-hack;
    text-align: center;
    top: -8px;
    right: -18px;
    .side-filter__text-hot {
      position: absolute;
      top: -2px;
      .left(-3px);
      width: 125%;
      height: 125%;
      display: inline-block;
      line-height: 1.5;
      transform: scale(0.8);
      transform-origin: center;
    }
  }
  img {
    width: 20px;
    height: 20px;
    border: 1px solid #e6e6e6 /*rtl:ignore*/;
    cursor: pointer;
    border-radius: 100%;
    &:hover {
      border-color: #222;
      padding: 2px;
    }
  }
  .sui-checkbox__checked {
    >img {
      border-color: #222;
      padding: 2px;
    }
  }
  /* stylelint-disable-next-line selector-max-specificity */
  .sui-checkbox__checked.sui-checkbox__disabled .sui-checkbox__label-select {
    background-color: #222;
    border-color: #222;
    fill: #fff;
  }
  /* stylelint-disable-next-line selector-max-specificity */
  .sui-checkbox__disabled .sui-checkbox__label-select {
    background-color: #fff;
    border-color: #ccc;
    fill: #fff;
  }
  /* stylelint-disable-next-line selector-max-specificity */
  .sui-checkbox__disabled .sui-checkbox__label-text {
    color: #222;
  }
}
</style>
