<template>
  <div
    class="side-filter__item"
    tabindex="0"
    role="heading"
    :aria-expanded="!!open"
    :aria-hidden="!!open"
    aria-level="3"
    :aria-label="navConfig.text + (navConfig.extraText || '')"
    @keydown.space.prevent="toggle"
    @keydown.enter.stop="toggle('enter')"
  >
    <div 
      class="side-filter__item-header" 
      @click="toggle"
    >
      <h3
        class="side-filter__item-header-title"
        :tabindex="open ? -1 : 0"
        role="button"
        :aria-label="navConfig.text + (navConfig.extraText || '')"
      >
        {{ navConfig.text }} {{ navConfig.extraText }}
      </h3>
      <sui_icon_delete_18px 
        v-if="panelConfig.selectedNum > 0 && navConfig.resetIcon"
        :class="[
          'nav-menu__delete-icon',
          !navConfig.icons ? 'nav-menu__delete-icon_right' : '',
        ]"
        tabindex="0"
        role="button"
        aria-label="delete"
        size="12px"
        style="line-height: 18px"
        @click.stop="resetCurItem"
        @keydown.enter.stop="resetCurItem"
      />
      <ClientOnly>
        <template v-if="navConfig.icons && loaded">
          <span v-show="open">
            <sui_icon_min_16px
              class="nav-menu__icon"
              size="16px"
            />
          </span>
          <span v-show="!open">
            <sui_icon_add_16px
              class="nav-menu__icon"
              size="16px"
            />
          </span>
        </template>
      </ClientOnly>
    </div>
    <div 
      v-show="open || !navConfig.icons" 
      class="side-filter__item-content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { ClientOnly } from '@sheinfe/vue-client-only'
import { sui_icon_min_16px, sui_icon_add_16px, sui_icon_delete_18px  } from '@shein-aidc/icon-vue3'

export default {
  components: {
    ClientOnly,
    sui_icon_min_16px,
    sui_icon_add_16px,
    sui_icon_delete_18px,
  },
  props: {
    /**
     * text[string]	筛选头显示文案
     * icons[object]	显示的icon
     * 	 normal[string] 默认icon的类名
     * 	 active[string] 展开icon的类名
     * resetIcon[Boolean] 是否显示重置icon
     * open[Number，Boolean] 展开控制
     * extraText[String] 需要在title旁边展示的额外文字
     */
    navConfig: {
      type: Object,
      default: () => ({}),
    },
    panelConfig: {
      type: Object,
      default: () => ({}),
    },
    loaded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open: false,
    }
  },
  watch: {
    navConfig: {
      handler(navConfig) {
        // 初始化展开状态
        this.open = navConfig?.open
      },

      immediate: true,
    },
  },
  mounted() {
    this.handleKey()
  },
  methods: {
    // 点击esc ADA 收缩
    async handleKey() {
      await this.$nextTick()
      const _this = this
      this.$el.addEventListener('keydown', function (e) {
        if (e.keyCode == 27 && _this.open) {
          _this.toggle()
          _this.$el?.focus()
        }
      })
    },
    toggle(type) {
      if (type === 'enter' && this.open) return // 回车事件 并打开时不触发。 等esc 才触发
      this.open = !this.open
      const { data, key } = this.panelConfig
      this.$emit('togglePanel', { data, key, openStatus: this.open })
    },
    resetCurItem() {
      this.$emit('resetCurItem', this.panelConfig)
    },
  },
  emits: ['togglePanel', 'resetCurItem'],
}
</script>

<style lang="less">
.side-filter {
  &__item {
    padding-top: 20px;
    padding-bottom: 16px;
  }
  &__item-header {
    position: relative;
    cursor: pointer;
    font-size: 14px;
    min-height: 18px;
    line-height: 18px;
    .nav-delete-btn {
      position: absolute;
      top: 0;
      .right(17px);
      .padding-r(5px);
      color: rgba(0, 0, 0, 0.3);
      &:hover {
        color: #222;
      }
    }
    .nav-menu__delete-icon {
      position: absolute;
      top: 0;
      .right(17px);
      .padding-r(5px);
      color: rgba(0, 0, 0, 0.3);
      &_right {
        .right(0);
      }
      &:hover {
        color: #222;
      }
    }
    .nav-menu__icon {
      position: absolute;
      top: 0;
      .right(0);
      font-weight: 400;
      color: #222;
      font-size: 16px;
      &:hover {
        color: #222;
      }
    }
  }

  &__item-header-title {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    .line-camp(1);
    text-transform: capitalize; /* 首字母大写 */
    /* rw:begin */
    font-family: 'Adieu-Bold', 'Adieu';
  }
  &__item-content {
    .clearfix();
    margin-top: 10px;
  }
}
</style>
