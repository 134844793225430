<template>
  <div
    ref="sideFilter"
    :class="['side-filter', ieFixed ? 'side-filter_fixed' : '', 'not-fsp-element']"
    :style="styleObj"
  >
    <!-- 辅助识别标签 ADA -->
    <h2
      tabindex="0"
      :role="language.SHEIN_KEY_PC_16403"
      style="position: fixed; top: -99999px; left: -99999px"
    >
      {{ language.SHEIN_KEY_PC_16403 }}
    </h2>
    <div 
      v-if="showSelectedFilter"
      class="side-filter__top"
    >
      <div class="header">
        <!-- 顶部标题 -->
        <div class="header__title">
          {{ language.SHEIN_KEY_PC_22311 }}
          <template v-if="filterNum">
            ({{ filterNum }})
          </template>
        </div>
        <!-- 筛选重置按钮 -->
        <div 
          v-if="filterNum"
          class="header__clear"
          @click="resetFilter"
          @keydown.enter="resetFilter"
        >
          {{ language.SHEIN_KEY_PC_15807 }}
        </div>
      </div>

      <!-- 已选筛选词 -->
      <div 
        v-if="selectedFilter.length"
        class="filter-words"
      >
        <div 
          v-for="item in selectedFilter"
          :key="item.label_id"
          class="filter-words__item"
          @click="deleteSeletedFilterItem(item)"
        >
          <img 
            v-if="item.img"
            :src="item.img"
          />
          <span 
            class="filter-words__item-word"
            :title="item.label"
          >{{ item.label }}</span>
          <sui_icon_closed_filter_12px_1 
            size="16px"
            color="rgba(34, 34, 34, 1)"
          />
        </div>
      </div>
    </div>
    <div
      class="side-filter__inner"
      :style="styleInnerObj"
      role="application"
      :aria-label="language.SHEIN_KEY_PC_16403"
    >
      <!-- 筛选重置按钮 -->
      <div
        v-if="filterNum && !showSelectedFilter"
        class="side-filter__clearAll"
        tabindex="0"
        @click="resetFilter()"
        @keydown.enter="resetFilter()"
      >
        {{ language.SHEIN_KEY_PC_15807 }} ({{ filterNum }})
      </div>

      <LazyHydrationWrapper :when-triggered="true">
        <NavMenu
          v-for="(item, index) in navConfig"
          :key="item.key"
          :nav-config="item"
          :panel-config="panelConfig[index]"
          :loaded="loaded"
          @reset-cur-item="resetCurItem"
          @toggle-panel="togglePanel"
        >
          <template v-if="panelConfig[index].type == 'radio'">
            <Tree
              :language="language"
              :panel-config="panelConfig[index]"
              :loaded="loaded"
              :disabled="disabled"
              @filter-change="filterChange"
              @toggle-child-panel="toggleChildPanel"
            />
          </template>
          <template v-else-if="panelConfig[index].type == 'symbol'">
            <SymbolItem
              :panel-config="panelConfig[index]"
              :lazy-img="lazyImg"
              :transform-img="transformImg"
              :disabled="disabled"
              @filter-change="filterChange"
            />
          </template>
          <template v-else-if="panelConfig[index].isCustomAttr">
            <CheckboxFilterList
              :language="language"
              :panel-config="panelConfig[index]"
              :loaded="loaded"
              :disabled="disabled"
              :lazy-img="lazyImg"
              :transform-img="transformImg"
              @filter-change="filterChange"
            />
          </template>
          <template v-else-if="panelConfig[index].type == 'checkbox'">
            <CheckboxFilterItem
              :language="language"
              :panel-config="panelConfig[index]"
              :loaded="loaded"
              :disabled="disabled"
              @filter-change="filterChange"
            />
          </template>
          <template v-else-if="panelConfig[index].type == 'dragPrice'">
            <PriceSlider
              ref="priceSlider"
              :language="language"
              :panel-config="panelConfig[index]"
              :common-config="commonConfig"
              :disabled="disabled"
              @filter-change="filterChange"
              @handle-expose="handlePriceSliderExpose"
            />
          </template>
        </NavMenu>
      </LazyHydrationWrapper>
    </div>
    <slot></slot>
  </div>
</template>

<script>

import { throttle } from '@shein/common-function'
import { isIE } from '../common/utils'
import NavMenu from '../components/NavMenu'
import Tree from '../components/Tree'
import CheckboxFilterList from '../components/CheckboxFilterList'
import CheckboxFilterItem from '../components/CheckboxFilterItem'
import SymbolItem from '../components/SymbolItem'
import PriceSlider from '../components/PriceSlider'
import { webScrollExpose, ScrollExpose } from 'public/src/pages/common/analysis/ScrollExpose'
import { LazyHydrationWrapper } from '@shein-aidc/shein-lazy-hydration'
import { sui_icon_closed_filter_12px_1  } from '@shein-aidc/icon-vue3'

export default {
  name: 'SSideFilter',
  components: {
    NavMenu,
    Tree,
    CheckboxFilterList,
    CheckboxFilterItem,
    PriceSlider,
    SymbolItem,
    LazyHydrationWrapper,
    sui_icon_closed_filter_12px_1,
  },
  props: {
    /**
     * 一些公共配置
     *  currency
     *  currencies
     *  lang
     *  GB_cssRight
     *  */
    commonConfig: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 导航标题配置
     * navConfig[Array]
     *   [object]
     *     text[string]	筛选头显示文案
     *     icons[object]
     * 	     normal[string] 不展开icon的类名
     * 	     active[string] 展开icon的类名
     *       open[Number]  1展开当前项 默认不展开
     *     resetIcon[Boolean] true带删除当前筛选项icon 默认不带删除icon
     */
    navConfig: {
      type: Array,
      default: () => [],
    },
    /**
   * 下拉面板配置
   * panelConfig[Array]
    * type[String] radio（单选树结构或者单层结构）/checkbox（多选单层结构）/symbol(色块)/dragPrice（价格拖拽）,
    * key[String] 自定义每一个筛选项的key，必须是唯一，filterChange回调时，调用方知道当前点击的是哪一项，好做逻辑处理
    * data[Object, Array],
    * sliceLen[Number] 需要截取展示的筛选项个数，传了此参数，则展示view more按钮
    * selectedNum[Number] 当前筛选面板选中的个数，给顶部clear all，回显数量使用
    *
    * *****type为radio时的参数 S*****
    * curSelectedId[Number, String] 当前选中的筛选项，回显时使用，radio
    * *****type为radio时的参数 E*****
    *
    * *****type为checkbox时的参数 S*****
    * itemStyles[Object] 覆盖筛选项里面每个筛选颗粒的样式
    * *****type为radio时的参数 E*****
    *
    * *****type为symbol时的参数 S*****
    * itemStyles[Object] 覆盖筛选项里面每个筛选颗粒的样式
    * *****type为radio时的参数 E*****

    * *****type为dragPrice时的参数 S*****
    * curMin[String, Number] 初始化当前的最小项
    * curMax[String, Number] 初始化当前的最大项
    * *****type为radio时的参数 E*****
  */
    panelConfig: {
      type: Array,
      default: () => [],
    },
    // 滚动吸顶
    ceiling: {
      type: Boolean,
      default: false,
    },
    // 懒加载占位图
    lazyImg: {
      type: String,
      default: '',
    },
    // 处理图片
    transformImg: {
      type: [String, Function],
      default: '',
    },
    // 选中的筛选词
    selectedFilter: {
      type: Array,
      default: () => ([])
    },
    // 顶部展示选中的筛选词
    showSelectedFilter: {
      type: Boolean,
      default: false
    },
    // 禁止点击（一般解决上次请求还没返回，反复点击的情况）
    disabled: {
      type: Boolean,
      default: false
    },
    // 头部高度 在页面下滑吸顶时，
    // 如果页面有自定义的头部，代替了公共头部，需要的传入头部高度
    headerHeight: {
      type: Number,
      default: 0,
    },
    // 是否展示 color 新样式
    isNewColorStyle: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      ieFixed: false,
      filterNum: 0, // 当选选中的筛选个数
      styleObj: {},
      styleInnerObj: {},
      loaded: false,
      clientHeight: 0,
      lastScrollTop: 0,
      scrollBarHeight: 0,
      // selectedFilterCopy: []
    }
  },
  provide() {
    return {
      isImgDataTypeDirectionColumnAbtOn: () => this.isImgDataTypeDirectionColumnAbtOn,
    }
  },
  computed: {
    isImgDataTypeDirectionColumnAbtOn() {
      return this.isNewColorStyle
    },
    isDirectionColumnImg() {
      return this.isImgDataTypeDirectionColumnAbtOn
    },
    language() {
      return this.commonConfig.language
    },
  },
  watch: {
    isDirectionColumnImg: {
      handler(v) {
        this.styleInnerObj.width =  v ? '240px' : ''
      },
      immediate: true,
    },
    selectedFilter: {
      handler(selectedFilter) {
        this.filterNum = selectedFilter.length
      },
      immediate: true
    }
  },
  mounted() {
    // 滚动吸顶效果
    if (this.ceiling) {
      this.initSideHeight()
    }
    this.loaded = true
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    filterChange(payload) {
      let { panelConfig } = payload

      // 组装透传父组件的数据
      payload.key = panelConfig.key
      delete payload.panelConfig // 去除多余字段

      this.$emit('filterChange', payload)
    },
    // 处理symbol和checkbox
    // handleCheckboxAndSymbol(payload) {
    //   let { panelConfig, operationType } = payload
    //   if (operationType == 'select') {
    //     // 选中
    //     this.controlSelectedNum(panelConfig, 'plus')
    //     this.controlFilterNum('plus')
    //   } else {
    //     // 反选
    //     this.controlSelectedNum(panelConfig, 'minus')
    //     this.controlFilterNum('minus')
    //   }
    // },
    // 重置筛选
    resetFilter() {
      // 透传父组件事件
      this.$emit('resetAllFilters')

      this.controlFilterNum('reset')
      // this.selectedFilterCopy.splice(0, this.selectedFilterCopy.length)
      this.panelConfig.forEach((configItem) => {
        if (configItem.type == 'radio') {
          configItem['curSelectedId'] = ''
        } else if (configItem.type == 'checkbox' || configItem.type == 'symbol') {
          configItem.data.children.forEach((filterItem) => {
            filterItem['isSelected'] = false
          })
        } else if (configItem.type == 'dragPrice') {
          this.$refs?.priceSlider?.forEach((item) => {
            item.resetSlider()
          })
        }
        this.controlSelectedNum(configItem, 'reset')
      })
    },
    // 重置当前筛选项
    resetCurItem(panelConfig) {
      // 透传父组件事件
      this.$emit('resetCurFilter', {
        id: panelConfig.id || '',
        type: panelConfig.type,
        key: panelConfig.key
      })

      // this.controlFilterNum('minus', panelConfig.selectedNum)

      if (['checkbox', 'symbol', 'tsp'].includes(panelConfig.type)) {
        panelConfig.data.children.forEach((item) => {
          item['isSelected'] = false
        })
      } else if (panelConfig.type == 'dragPrice') {
        this.$refs?.priceSlider[0]?.resetSlider()
      }

      this.controlSelectedNum(panelConfig, 'reset')
    },
    // 控制筛选项总个数
    controlFilterNum(type, dynamicNum) {
      const num = dynamicNum || 1

      if (!this.filterNum || type == 'reset') {
        this.filterNum = 0
      }
      if (type == 'plus') {
        this.filterNum += num
      } else if (type == 'minus') {
        this.filterNum -= num
      }
    },
    // 控制每个筛选项的选择个数 reset: 置空， plus: 累加，minus: 累减
    controlSelectedNum(filterItem, type) {
      if (type == 'reset') {
        filterItem.selectedNum = 0
      } else if (type == 'plus' || type == 'minus') {
        if (!filterItem.selectedNum) {
          filterItem.selectedNum = 0
        }
        if (type == 'plus') {
          filterItem.selectedNum += 1
          return
        }
        filterItem.selectedNum -= 1
      }
    },
    // 外层panel展开收起
    togglePanel(payload) {
      this.$emit('togglePanel', payload)
    },
    // 内层panel展开收起
    toggleChildPanel(payload) {
      this.$emit('toggleChildPanel', payload)
    },
    // 给side-filter设置初始高度
    initSideHeight() {
      this.clientHeight = document.body.clientHeight || document.documentElement.clientHeight
      this.scrollBarHeight = parseFloat(window.getComputedStyle(this.$refs.sideFilter).paddingBottom)
      this.lastScrollTop = document.body.scrollTop || document.documentElement.scrollTop
      document.addEventListener(
        'scroll',
        throttle({
          func: this.handleScroll,
          wait: 200,
          options: { trailing: true, leading: true },
        })
      )
      this.handleScroll()
      // 高度设置后再设置overflow，ssr时让侧边栏高度占满
      this.$nextTick(() => {
        this.$refs.sideFilter.style.overflow = 'auto'
      })
    },
    handleScroll() {
      const nowScrollTop = document.body.scrollTop || document.documentElement.scrollTop
      const scrollDown = nowScrollTop - this.lastScrollTop > 0
      this.lastScrollTop = nowScrollTop

      const topHeaderHeight = this.headerHeight || document.querySelector('.c-header').clientHeight // 页面头部高度，必须实时获取，因为topBanner为异步获取和可以删除操作

      const subScrollTop =
        topHeaderHeight +
        Array.from(document.querySelectorAll('.scrollfix-sub')).reduce(
          (curr, next) => (curr += next.offsetHeight),
          0
        ) +
        this.scrollBarHeight

      let styleObj = null
      if (nowScrollTop <= subScrollTop) {
        const target = this.clientHeight - (subScrollTop - nowScrollTop)
        styleObj = {
          height: `${target}px`,
        }
        if (!scrollDown) {
          this.ieFixed = false
          this.$emit('ieFixed', false)
        }
        this.$emit('isSticky', false)
      } else {
        if (scrollDown) {
          const extraTopHeightAcc = Array.from(document.querySelectorAll('.extra-top-height')).reduce(
            (curr, next) => (curr += next.offsetHeight),
            0
          )
          const target = this.clientHeight - extraTopHeightAcc - this.scrollBarHeight
          if (parseFloat(this.styleObj.height) === target) return

          styleObj = {
            height: `${target}px`,
          }
          if (isIE()) {
            this.ieFixed = true
            this.$emit('ieFixed', true)
          }

          this.$emit('isSticky', true)
        } else {
          const target = this.clientHeight - this.scrollBarHeight - topHeaderHeight

          if (parseFloat(this.styleObj.height) === target) return

          const outSticky = this.$refs['sideFilter']?.getBoundingClientRect().top < 0 // 脱离sticky

          if (outSticky) return

          styleObj = {
            height: `${target}px`,
            transform: `translateY(${topHeaderHeight}px)`,
          }
        }
      }
      if (this.isDirectionColumnImg) {
        styleObj.flex = '0 0 246px'
      }
      this.styleObj = Object.assign(styleObj, { transition: 'transform .3s' })
    },
    handlePriceSliderExpose() {
      const exposeDom = document.querySelector('.side-filter__price')
      // 侧边滚动曝光
      this.scrollExpose = new ScrollExpose({
        scrollDom: this.$refs['sideFilter'],
      })
      this.scrollExpose.subscribe({
        dom: exposeDom,
        callback: () => {
          this.$emit('exposePriceSlider')
        },
      })

      // window滚动曝光
      webScrollExpose.subscribe({
        dom: exposeDom,
        callback: () => {
          this.$emit('exposePriceSlider')
        },
      })
    },
    // 删除筛选词
    deleteSeletedFilterItem(item) {
      const { key, label_id } = item
      // 删除选中的筛选词
      // this.selectedFilterCopy = this.selectedFilterCopy?.filter(_ => _.label_id !== label_id)
      // 反选对应的筛选项
      for(let i = 0; i < this.panelConfig.length; i++) {
        const config = this.panelConfig[i]
        if (config.key == key) {
          if (config.type == 'radio') {
            config.curSelectedId  = -1
            break
          } else if (['checkbox', 'symbol'].includes(config.type)) {
            const data = config.data.children || []
            const targetIndex = data.findIndex(item => item.label_id == label_id)
            if (targetIndex > -1) {
              data[targetIndex].isSelected = false
              break
            }
          } else if (config.type == 'dragPrice') {
            this.$refs?.priceSlider?.forEach((item) => {
              item.resetSlider()
            })
            break
          }
        }
      }

      this.$emit('delSelectedFilter', item, { delFrom: 'filterWords' })
    }
  },
  emits: [
    'resetCurFilter',
    'filterChange',
    'togglePanel',
    'toggleChildPanel',
    'ieFixed',
    'isSticky',
    'resetAllFilters',
    'exposePriceSlider',
    'delSelectedFilter'
  ],
}
</script>

<style lang="less">
.side-filter {
  flex: 0 0 186px;
  box-sizing: content-box;
  position: sticky;
  top: 0;
  .padding-r(25px);
  padding-bottom: 10px;
  height: 700px;
  font-family: Muli, Arial, Helvetica, sans-serif !important; /* stylelint-disable-line declaration-no-important */
  &::-webkit-scrollbar {
    width: 6px !important; /* stylelint-disable-line declaration-no-important */
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #e0e0e0;
  }
  &::-webkit-scrollbar-track {
    margin: 13px 0 13px 0;
  }
  &__top {
    padding: 10px 0 20px;
    .header {
      .flexbox();
      .space-between();
      height: 18px;
      line-height: 18px;
      &__title {
        font-weight: bold;
        font-size: 14px;
      }
      &__clear {
        font-size: 12px;
        color: @sui_color_link;
        cursor: pointer;
      }
    }
    .filter-words {
      .flexbox();
      flex-wrap: wrap;
      padding-top: 2px;
      &__item {
        .flexbox();
        .space-between();
        .align-center();
        max-width: 100%;
        height: 30px;
        line-height: 30px;
        margin: 12px 12px 0 0;
        padding: 6px;
        border: 1px solid rgba(34, 34, 34, 1);
        cursor: pointer;
        > img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 2px;
          margin-left: 6px;
          border: 1px solid rgba(229, 229, 229, 1);
        }
      }
      &__item-word {
        .text-overflow();
        font-size: 12px;
        color: rgba(0, 0, 0, 1);
        margin: 0 4px;
        white-space: nowrap;
      }
    }
  }
  &__inner {
    width: 180px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
  &__clearAll {
    width: 100%;
    height: 36px;
    line-height: 36px;
    color: @sui_color_main;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    background-color: #fff;
    border: 1px solid @sui_color_main;
    transition: all 0.3s;
    margin-top: 16px;
    cursor: pointer;
    /* rw:begin */
    font-family: 'Adieu';
    &:hover {
      background-color: @sui_color_main;
      color: #fff;
    }
  }
}


/* 针对筛选栏在左侧的情况且为 IE 的情况，因为筛选栏 fixed 后需要补宽度 */
.product-list-v2__main_ie {
  position: relative;
  .padding-l(205px);
  .side-filter {
    position: absolute;
    .left(0);
    width: 180px;
    box-sizing: content-box;
  }
}
.product-list-v2__main_side-ie {
  .padding-l(205px);
  .side-filter_fixed {
    position: fixed;
    top: 0;
    .left(50px);
    width: 180px;
    box-sizing: content-box;
  }
}
</style>
